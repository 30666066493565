import React, { useState, useEffect } from "react";

//MUI
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";

import { DataGrid } from "@mui/x-data-grid";

//Icons
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { useNavigate } from "react-router-dom";

import DeleteRoleDialog from "../../components/dialogs/deleteGeneric";
import CreateRoleDialog from "../../components/dialogs/createRole";
import RolePopover from "../../components/popovers/generic";

import mainURL from "../../config/environment";
import $ from "jquery";

const emptyData = {
  id: "",
  name: "",
};

const usePermissions = (refresh) => {
  const [permissions, setPermissions] = useState([]);
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    const roleId = JSON.parse(localStorage.getItem("userInfo")).roleId;
    $.ajax({
      method: "GET",
      url: mainURL + `permissions/${roleId}`,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    }).done((res) => {
      const aux = [];
      res.forEach((element) => {
        if (element.isAssigned) aux.push(element.permission);
      });
      setPermissions(aux);
    });
  }, []);
  return permissions;
};

const deleteMessage = "There are users related to this role, delete them first";

export default function Roles(props) {
  const navigate = useNavigate();

  //Permission management
  const permissions = usePermissions();
  const addPermission = permissions.find((x) => x === 6);
  const updatePermission = permissions.find((x) => x === 7);

  //Data management
  const [selectedData, setSelectedData] = useState(emptyData);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);
  let columns: GridColDef[] = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
    },
    {
      headerName: "Options",
      field: "id",
      renderCell: (params: GridRenderCellParams) => (
        <IconButton onClick={handlePopoverOpen(params.row)}>
          <MoreVertIcon />
        </IconButton>
      ),
      //flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      editable: false,
    },
  ];

  if (!updatePermission) {
    columns = columns.filter((x) => x.headerName !== "Options");
  }

  //Dialog management
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [createDialog, setCreateDialog] = useState(false);
  const handleCloseDialogs = () => {
    setDeleteDialog(false);
    setCreateDialog(false);
  };

  //Notification management
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState({
    severity: "",
    message: "",
  });

  const handleShowNotification = (severity, message) => {
    setNotificationMessage({ severity: severity, message: message });
    setShowNotification(true);
    setTimeout(function () {
      setShowNotification(false);
    }, 5000);
  };

  //Popover management
  const [anchor, setAnchor] = useState(null);
  const handlePopoverOpen = (selected) => (event) => {
    event.stopPropagation();
    setAnchor(event.currentTarget);
    setSelectedData(selected);
  };

  const handlePopoverClose = () => {
    setAnchor(null);
  };

  const handleSelect = (data) => {
    if (data.row !== selectedData) setSelectedData(data.row);
    else setSelectedData(emptyData);
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    let isSubscribed = true;
    //handleShowNotification("info", "Loading roles");
    $.ajax({
      method: "GET",
      url: mainURL + "role/get-all",
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .done((res) => {
        const aux: GridRowsProp = res;
        if (isSubscribed) {
          setData(aux);
          //handleShowNotification("success", "Roles loaded successfully");
        }
      })
      .fail((res) => {
        handleShowNotification("error", res.responseText);
      });
    return () => (isSubscribed = false);
  }, [refresh]);

  return (
    <Grid container direction="column" sx={{ p: 3 }}>
      <RolePopover
        open={anchor}
        showEditRole
        roleId={selectedData.id}
        handleClose={handlePopoverClose}
        setDeleteDialog={setDeleteDialog}
      />
      <DeleteRoleDialog
        refresh={refresh}
        open={deleteDialog}
        title={"Delete role"}
        setRefresh={setRefresh}
        name={selectedData.name}
        errorMessage={deleteMessage}
        handleClose={handleCloseDialogs}
        deleteURL={`role/${selectedData.id}`}
        handleShowNotification={handleShowNotification}
      />
      <CreateRoleDialog
        refresh={refresh}
        open={createDialog}
        setRefresh={setRefresh}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <Grid
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ p: "2rem 0" }}
        spacing={2}
        container
      >
        <Grid item xs={4} md={8}>
          <Typography variant="h4">{"Roles"}</Typography>
        </Grid>

        {showNotification ? (
          <Grid item xs={12} md={4}>
            <Alert variant="outlined" severity={notificationMessage.severity}>
              {notificationMessage.message}
            </Alert>
          </Grid>
        ) : addPermission ? (
          <Grid item xs={4} md={4} container justifyContent="flex-end">
            <Button variant="contained" onClick={() => navigate("/roles/add")}>
              <PlaylistAddIcon sx={{ mr: 1 }} />
              {"Add"}
            </Button>
          </Grid>
        ) : null}
      </Grid>

      <Grid container sx={{ height: "65vh" }}>
        <DataGrid
          selectionModel={selectedData.id === "" ? [] : selectedData.id}
          onRowClick={handleSelect}
          columns={columns}
          disableColumnMenu
          autoPageSize
          rows={data}
        />
      </Grid>
    </Grid>
  );
}
