import React, { useState, useEffect } from "react";

//MUI
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";

import { DataGrid } from "@mui/x-data-grid";

//Icons
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import DeleteClientDialog from "../../components/dialogs/deleteGeneric";
import CreateClientDialog from "../../components/dialogs/createClient";
import UpdateClientDialog from "../../components/dialogs/updateClient";
import ClientPopover from "../../components/popovers/generic";

import mainURL from "../../config/environment";
import $ from "jquery";

const emptyData = {
  id: "",
  name: "",
  address: "",
  mapURL: "",
  mapTitle: "",
};

const usePermissions = (refresh) => {
  const [permissions, setPermissions] = useState([]);
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    const roleId = JSON.parse(localStorage.getItem("userInfo")).roleId;
    $.ajax({
      method: "GET",
      url: mainURL + `permissions/${roleId}`,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    }).done((res) => {
      const aux = [];
      res.forEach((element) => {
        if (element.isAssigned) aux.push(element.permission);
      });
      setPermissions(aux);
    });
  }, []);
  return permissions;
};

const deleteMessage =
  "There are users related to this client, delete them first";

export default function Clients(props) {
  //Permission management
  const permissions = usePermissions();
  const addPermission = permissions.find((x) => x === 10);
  const updatePermission = permissions.find((x) => x === 11);

  const screenSize = window.innerWidth;

  //Data management
  const [selectedData, setSelectedData] = useState(emptyData);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);
  let columns: GridColDef[] = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
    },
    {
      headerName: "Address",
      field: "address",
      flex: 1,
    },
    {
      headerName: "Options",
      field: "id",
      renderCell: (params: GridRenderCellParams) => (
        <IconButton onClick={handlePopoverOpen(params.row)}>
          <MoreVertIcon />
        </IconButton>
      ),
      //flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      editable: false,
    },
  ];

  if (!updatePermission) {
    columns = columns.filter((x) => x.headerName !== "Options");
  }

  if (screenSize < 600) {
    columns = columns.filter(
      (x) => x.headerName === "Options" || x.headerName === "Name"
    );
  }

  //Dialog management
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [createDialog, setCreateDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);
  const handleCloseDialogs = () => {
    setDeleteDialog(false);
    setCreateDialog(false);
    setUpdateDialog(false);
    setSelectedData(emptyData);
  };

  //Notification management
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState({
    severity: "",
    message: "",
  });

  const handleShowNotification = (severity, message) => {
    setNotificationMessage({ severity: severity, message: message });
    setShowNotification(true);
    setTimeout(function () {
      setShowNotification(false);
    }, 5000);
  };

  //Popover management
  const [anchor, setAnchor] = useState(null);
  const handlePopoverOpen = (selected) => (event) => {
    event.stopPropagation();
    setAnchor(event.currentTarget);
    setSelectedData(selected);
  };

  const handlePopoverClose = () => {
    setAnchor(null);
  };

  const handleSelect = (data) => {
    if (data.row !== selectedData) setSelectedData(data.row);
    else setSelectedData(emptyData);
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    let isSubscribed = true;
    //handleShowNotification("info", "Loading clients");
    $.ajax({
      method: "GET",
      url: mainURL + "client/get-all",
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .done((res) => {
        const aux: GridRowsProp = res.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        if (isSubscribed) {
          setData(aux);
          //handleShowNotification("success", "Clients loaded successfully");
        }
      })
      .fail((res) => {
        handleShowNotification("error", res.responseText);
      });
    return () => (isSubscribed = false);
  }, [refresh]);

  return (
    <Grid container direction="column" sx={{ p: 3 }}>
      <ClientPopover
        open={anchor}
        showUpdateUser
        setUpdateDialog={setUpdateDialog}
        handleClose={handlePopoverClose}
        setDeleteDialog={setDeleteDialog}
      />
      <DeleteClientDialog
        refresh={refresh}
        open={deleteDialog}
        setRefresh={setRefresh}
        title={"Delete client"}
        name={selectedData.name}
        errorMessage={deleteMessage}
        handleClose={handleCloseDialogs}
        deleteURL={`client/${selectedData.id}`}
        handleShowNotification={handleShowNotification}
      />
      <CreateClientDialog
        refresh={refresh}
        open={createDialog}
        setRefresh={setRefresh}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <UpdateClientDialog
        refresh={refresh}
        open={updateDialog}
        setRefresh={setRefresh}
        clientId={selectedData.id}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <Grid
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ p: "2rem 0" }}
        spacing={2}
        container
      >
        <Grid item xs={4} md={8}>
          <Typography variant="h4">{"Clients"}</Typography>
        </Grid>

        {showNotification ? (
          <Grid item xs={12} md={4}>
            <Alert variant="outlined" severity={notificationMessage.severity}>
              {notificationMessage.message}
            </Alert>
          </Grid>
        ) : addPermission ? (
          <Grid item xs={4} md={4} container justifyContent="flex-end">
            <Button variant="contained" onClick={() => setCreateDialog(true)}>
              <PlaylistAddIcon sx={{ mr: 1 }} />
              {"Add"}
            </Button>
          </Grid>
        ) : null}
      </Grid>

      <Grid container sx={{ height: "65vh" }}>
        <DataGrid
          selectionModel={selectedData.id === "" ? [] : selectedData.id}
          onRowClick={handleSelect}
          columns={columns}
          disableColumnMenu
          autoPageSize
          rows={data}
        />
      </Grid>
    </Grid>
  );
}
