import React, { useEffect, useState } from "react";

import $ from "jquery";
import mainURL from "../../config/environment";

import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
// import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import backgroundLines from "../../assets/linesbackground.png";

import { useNavigate } from "react-router-dom";
import { CardActionArea, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { VisibilityRounded } from "@mui/icons-material";
import Footer from "../../components/footer/footer";

const styles = {
  cardHovered: {
    // opacity: '90%',
    "&:hover": {
      // background: "white",
      // opacity: '50%',
    },
  },
  cardOnHover: {
    width: "100%",
    height: "70%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(255,255,255,0.4)",
    flexDirection: "column",
    cursor: "pointer",
  },
};

export default function Maps(props) {
  const [maps, setMaps] = useState([]);
  const { handleDrawerClose } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const handleNavigate = (mapId) => (event) => {
    navigate(`/maps/${mapId}`);
    handleDrawerClose();
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    const clientId = JSON.parse(localStorage.getItem("userInfo")).clientId;
    let isSubscribed = true;
    //handleShowNotification("info", "Loading users");
    $.ajax({
      method: "GET",
      url: `${mainURL}map/${clientId}/get-by-client`,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .done((res) => {
        if (isSubscribed) {
          setMaps(res);
        }
      })
      .fail((res) => {});
    return () => (isSubscribed = false);
  }, []);

  return (
    <Box
      height="100%"
      sx={{
        backgroundImage: `url(${backgroundLines})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "repeat-y",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        // bgcolor: 'yellow'
      }}
    >
      <Box>
        <Box sx={{ p: 2, pb: 0 }}>
          {" "}
          <Typography
            variant="h5"
            sx={{ color: theme.palette.gray.dark, fontWeight: "600" }}
          >
            {" "}
            Welcome to NNTU GIS System
          </Typography>
          <Typography variant="body2" sx={{ color: theme.palette.gray.main }}>
            {" "}
            Check the available maps here.
          </Typography>
        </Box>

        <Grid container sx={{ pl: 2 }}>
          {maps.map((map) => (
            <Grid key={map.id} sx={{ p: 2 }} item xs={12} sm={6} md={4} xl={3}>
              <Card
                variant="outlined"
                sx={{
                  maxHeight: "auto",
                  height: "13rem",
                  boxShadow: "none",
                  borderWidth: "thin",
                  borderColor: theme.palette.gray.mediumLight,
                  position: "relative",
                }}
                onClick={handleNavigate(map.id)}
                onMouseEnter={() => {
                  setOnMouseEnter(map.id);
                }}
                onMouseLeave={() => {
                  setOnMouseEnter(null);
                }}
                // sx={{ bckground: 'gray' }}
              >
                <CardActionArea />

                {onMouseEnter === map.id && (
                  <Box sx={styles.cardOnHover}>
                    <VisibilityRounded
                      sx={{
                        height: 30,
                        width: 30,
                        color: theme.palette.blues.dark,
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: "600",
                        color: theme.palette.blues.dark,
                        fontSize: "14px",
                      }}
                    >
                      View map
                    </Typography>
                  </Box>
                )}

                <CardMedia
                  component="img"
                  alt="map-preview"
                  height="140"
                  sx={styles.cardHovered}
                  image={map.mapPreview}
                />

                <CardContent
                  sx={{
                    minHeight: "5rem",
                    height: "5rem",
                    background: theme.palette.gray.superLight,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      background: theme.palette.gray.light,
                    },
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="body2"
                    component="div"
                    align="center"
                    sx={{
                      color: theme.palette.gray.main,
                      fontWeight: "500",
                      cursor: "pointer",
                      fontSize: "1rem",
                    }}
                  >
                    {map.mapTitle}
                  </Typography>
                </CardContent>
                <CardActions></CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box>
        <Footer />
      </Box>
    </Box>
  );
}
