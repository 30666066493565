import { Link, Typography } from "@mui/material";
import React from "react";

export default function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="white"
      align="center"
      fontWeight='500'

      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://americasrf.com/">
        {`Americas Communications`}
      </Link>
      {` ${new Date().getFullYear()}.`}
    </Typography>
  );
}
