import React from "react";

import Grid from "@mui/material/Grid";
import PieChart from "../../components/charts/pieChart";
import MainCharts from "../../components/charts/mainCharts";
import ClientChart from "../../components/charts/clientChart";

export default function Dashboard(props) {
  return (
    <Grid container sx={{ p: 3 }} spacing={2}>
      <MainCharts />
      <PieChart
        title="User distribution"
        subTitle="Based on the client"
        endpoint="dashboard/user-distribution"
      />
      <ClientChart
        title="Client's maps"
        subTitle="Based on the client"
        endpoint="dashboard/user-distribution"
      />
    </Grid>
  );
}
