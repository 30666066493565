import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import mainURL from "../config/environment";
import $ from "jquery";

import { useNavigate } from "react-router-dom";

export default function MapModule(props) {
  const [maps, setMaps] = useState([]);
  const { clientId } = useParams();
  const navigate = useNavigate();

  const handleNavigate = (mapId) => (event) => {
    navigate(`/${clientId}/${mapId}`);
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    let isSubscribed = true;
    //handleShowNotification("info", "Loading users");
    $.ajax({
      method: "GET",
      url: `${mainURL}map/${clientId}/get-by-client`,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .done((res) => {
        if (isSubscribed) {
          setMaps(res);
        }
      })
      .fail((res) => {});
    return () => (isSubscribed = false);
  }, [clientId]);

  return (
    <Grid container sx={{ p: 3 }} spacing={2}>
      {maps.map((map) => (
        <Grid key={map.id} item xs={12} sm={6} md={4}>
          <Card sx={{ height: "100%" }}>
            <CardMedia
              component="img"
              alt="map-preview"
              height="180"
              image={map.mapPreview}
            />
            <CardContent sx={{ minHeight: "8rem" }}>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                align="center"
              >
                {map.mapTitle}
              </Typography>
            </CardContent>
            <CardActions>
              <Grid container justifyContent={"center"}>
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleNavigate(map.id)}
                >
                  View
                </Button>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
