import React, { useState } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { LockPersonOutlined } from "@mui/icons-material";

import mainURL from "../../config/environment";
import $ from "jquery";
import { useTheme } from "@emotion/react";
import { Box, Divider, OutlinedInput } from "@mui/material";
import theme from "../../config/theme";

const emptyModel = {
  email: "",
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};
const styles = {
  passwordInput: {
    backgroundColor: 'white',
    borderRadius: '4px',
    opacity: '70%',
    fontWeight: '600',
    paddingLeft: 2,
    paddingRight: 2,
    height: '2.7rem',
    border: '2px',
    borderColor: 'red'
  },

  ButtonCss: {
    textTransform: 'unset',
    backgroundColor: theme.palette.blues.main,
    fontWeight: '600',
    fontSize: '14px',


  }
}

export default function ChangePasswordCard(props) {
  const [isPasswordValid, setPasswordValid] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [model, setModel] = useState(emptyModel);
  const theme = useTheme();


  const handleChange = (event) => {
    const email = JSON.parse(localStorage.getItem("userInfo")).email;
    const target = event.target;
    const name = target.name;
    let value = target.value;

    switch (name) {
      case "newPassword":
        const isPasswordValid = Boolean(
          value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,}$/i)
        );
        if (isPasswordValid) {
          setPasswordValid(true);
        } else {
          setPasswordValid(false);
        }
        setModel({
          ...model,
          [name]: value,
        });
        break;

      default:
        setModel({
          ...model,
          [name]: value,
          email: email,
        });

        break;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("userInfo")).token;

    $.ajax({
      method: "POST",
      url: mainURL + "login/change-password",
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
      data: JSON.stringify(model),
    })
      .done((res) => {
        setLoading(false);
        setModel(emptyModel);
        props.handleShowNotification(
          "success",
          "Password changed successfully"
        );
      })
      .fail((res) => {
        setLoading(false);
        setModel(emptyModel);
        props.handleShowNotification("error", "Password not changed");
      });
  };

  return (
    <Grid item md={12} >
      <Card variant="outlined" sx={{ bgcolor: theme.palette.gray.superLight, borderColor: theme.palette.gray.mediumLight }}>
        <CardHeader sx={{ py: '9px' }}
          title={<Box display={'flex'} alignItems={'center'}  > <LockPersonOutlined sx={{ marginRight: 2, color: theme.palette.gray.medium }} />  <Typography variant="h6" fontWeight={'600'} sx={{ color: theme.palette.gray.dark }}>{"Change password"}</Typography></Box>
          }
        />
        <Box sx={{ px: 2 }} >
          <Divider />
        </Box>

        <CardContent>
          <Grid container>
            <Grid item md={10}>
              <Box display={'flex'} flexDirection={'column'}>
                <Typography variant="body2" color="textSecondary">
                  {
                    "Password must contain at least one uppercase, one lowercase and one number."
                  }
                </Typography>

                <Grid container>
                  <Grid item md={4}>
                    <Box>
                      <FormControl fullWidth required sx={{ mt: 1 }}>
                        <Typography sx={{ color: theme.palette.gray.main, fontSize: '16px', mb: '0.5rem', fontWeight: '500' }}>{"Current password: "}</Typography>

                        <OutlinedInput sx={styles.passwordInput}
                          value={model.oldPassword}
                          margin="dense"
                          required
                          name="oldPassword"
                          type="password"
                          size="small"
                          onChange={handleChange}

                        />
                      </FormControl>
                      <FormControl fullWidth required sx={{ mt: 1 }}>
                        <Typography sx={{ color: theme.palette.gray.main, fontSize: '16px', mb: '0.5rem', fontWeight: '500' }}>{"New password: "}</Typography>
                        <OutlinedInput sx={styles.passwordInput}
                          error={!isPasswordValid}
                          value={model.newPassword}
                          margin="dense"
                          required
                          name="newPassword"
                          type="password"
                          size="small"
                          onChange={handleChange} />


                      </FormControl>
                      <FormControl fullWidth required sx={{ mt: 1 }}>
                        <Typography sx={{ color: theme.palette.gray.main, fontSize: '16px', mb: '0.5rem', fontWeight: '500' }}>{"Confirm password: "}</Typography>

                        <OutlinedInput sx={styles.passwordInput}
                          error={model.newPassword !== model.confirmPassword}
                          value={model.confirmPassword}
                          margin="dense"
                          required
                          name="confirmPassword"
                          type="password"
                          size="small"
                          onChange={handleChange}

                        />


                        {model.password !== model.confirmPassword ? (
                          <FormHelperText>{"Passwords don't match"}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item md={2}>
              {isLoading ? (
                <Grid container justifyContent="flex-end">
                  <CircularProgress />
                </Grid>
              ) : (
                <Grid container justifyContent="flex-end" alignItems="center">

                  <Button
                    sx={styles.ButtonCss}
                    disabled={
                      !isPasswordValid ||
                      model.oldPassword === "" ||
                      model.newPassword !== model.confirmPassword
                    }
                    onClick={handleSubmit}
                    variant='contained'
                  >
                    Change password
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid >
  );
}


