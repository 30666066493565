import React, { useState } from "react";

//MUI
import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";
// import Container from "@mui/material/Container";
// import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Input, Typography } from "@mui/material";
import backgroundImg from "../../assets/BackgroundImg2.jpeg";
//Selfmade
import Copyright from "./copyright";

//React router dom
import { useNavigate } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";
import mainURL from "../../config/environment";
import $ from "jquery";
//import { width } from "@mui/system";
import { styled } from "@mui/material/styles";
export default function ForgotPassword() {
  const navigate = useNavigate();

  //Load management
  const [isLoading, setLoading] = useState(false);
  const [model, setModel] = useState({
    email: "",
  });

  const ButtonCss = styled(Button)`
    background: #2c9add;
    font-weight: bold;
    text-transform: unset;
    font-size: 16px;
    &:hover,
    &:focus {
      background: #1e79af;
    }
    &:active {
      background: #1e79af;
    }
  `;

  const styles = {
    backgroundImg: {
      backgroundImage: `url(${backgroundImg})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundColor: "#0B3940",
    },
    passwordInput: {
      backgroundColor: "#E4E4E4",
      borderRadius: "4px",
      opacity: "70%",
      fontWeight: "600",
      paddingLeft: 2,
      paddingRight: 2,
      height: "2.7rem",
      border: "2px",
      borderColor: "red",
      width: "100%",
    },
    boxCenter: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    boxContainer: {
      width: "100%",
      height: "90%",
      // backgroundColor: 'red'
    },
    imgBoxContainer: {
      maxWidth: "32rem",
      // backgroundColor: 'blue'
    },
    titleBoxContainer: {
      mt: 6,
    },
    backButton: {
      backgroundColor: "transparent",
      borderColor: "white",
      textTransform: "unset",
      fontWeight: "bold",
      color: "white",
      "&:hover": {
        borderColor: "white",
      },
    },
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    $.ajax({
      method: "POST",
      url: mainURL + "login/forgot-password",
      contentType: "application/json",
      data: JSON.stringify(model),
    })
      .done((res) => {
        setLoading(false);
        localStorage.setItem("userInfo", JSON.stringify(res));
        navigate("/Login");
      })
      .fail((res) => {
        alert(res.responseText);
      });
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setModel({
      ...model,
      [name]: value,
    });
  };

  return (
    <Box
      component="main"
      style={styles.backgroundImg}
      sx={{
        height: "100vh",
        width: "100vw",
        padding: "1rem 2rem",
      }}
    >
      {/* <Container maxWidth="sm" > */}
      <CssBaseline />

      <Box
        sx={{
          height: "3rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          sx={styles.backButton}
          startIcon={<ArrowBackIos />}
          onClick={() => navigate("/Login")}
        >
          Back to login
        </Button>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          {" "}
          <Typography
            sx={{ fontSize: "16px", color: "white", fontWeight: "bold" }}
          >
            {"Navajo Nation Telecommunication & Utilities"}
          </Typography>
          <Typography
            sx={{ fontSize: "14px", color: "white" }}
          >{`"Connecting and Energizing the Nation!"`}</Typography>
        </Box>
      </Box>

      <Box sx={{ ...styles.boxCenter }}>
        <img
          src="assets/images/NNTU-logo.png"
          alt="Logo"
          style={{ maxWidth: "16rem" }}
        />
        <Box sx={{ ...styles.imgBoxContainer, ...styles.boxCenter }}>
          <Box sx={{ ...styles.titleBoxContainer, ...styles.boxCenter }}>
            <Typography
              variant="h2"
              sx={{
                color: "white",
                fontWeight: "700",
                fontSize: "40px",
                mb: "10px",
              }}
            >
              {"Forgot password?"}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "white", fontWeight: "400", textAlign: "center" }}
            >
              {
                "Enter the email address you used when you joined and we’ll send you instructions to reset your password."
              }
            </Typography>
          </Box>

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 5, width: "100%" }}
          >
            {/* <TextField
              margin="dense"
              required
              fullWidth
              id="email"
              label={"Email"}
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
            /> */}
            <Typography sx={{ color: "white", fontSize: "16px", mb: "0.5rem" }}>
              {"Email"}
            </Typography>

            <Input
              sx={styles.passwordInput}
              margin="dense"
              required
              name="email"
              type={"text"}
              id="email"
              autoComplete="email"
              onChange={handleChange}
            />

            {isLoading ? (
              <Grid container justifyContent={"center"} sx={{ mt: 3, mb: 2 }}>
                <CircularProgress />
              </Grid>
            ) : (
              <React.Fragment>
                <ButtonCss
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 1 }}
                >
                  {" "}
                  Get recover link
                </ButtonCss>
                {/* <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Get recover link
                </Button> */}

                {/* <BackButtonCss fullWidth
                  variant="outlined"

                  onClick={() => navigate("/Login")}> Back to Login

                </BackButtonCss> */}
                {/* <Button
                  sx={styles.backButton}
                  fullWidth
                  variant="outlined"

                  onClick={() => navigate("/Login")}
                >
                  Back to Login
                </Button> */}
              </React.Fragment>
            )}
          </Box>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
      {/* </Container> */}
    </Box>
  );
}
