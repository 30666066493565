import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import $ from "jquery";
import mainURL from "../../config/environment";
import { Typography, useTheme } from "@mui/material";

export default function SelectRole(props) {
  const { showAmericas, handleChange, value, name } = props;
  const theme = useTheme();

  const [roles, setRoles] = useState([]);
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    $.ajax({
      method: "GET",
      url: mainURL + "role/get-all",
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    }).done((res) => {
      const aux1 = res.filter((x) => !x.name.toLowerCase().includes("super"));
      if (showAmericas) {
        const aux = aux1.filter((x) =>
          x.name.toLowerCase().includes("americas")
        );
        setRoles(aux);
      } else {
        const aux = aux1.filter(
          (x) => !x.name.toLowerCase().includes("americas")
        );
        setRoles(aux);
      }
    });
  }, [showAmericas]);

  return (
    <FormControl fullWidth required sx={{ mt: 1 }}>
      <Typography
        sx={{
          color: theme.palette.gray.main,
          fontSize: "16px",
          mb: "0.5rem",
          fontWeight: "500",
        }}
      >
        {"Role: * "}
      </Typography>
      <Select
        value={value}
        onChange={handleChange}
        name={name}
        size="small"
        native
        // label="Select a role"
      >
        <option value="" />
        {roles.map((role) => (
          <option key={role.id} value={role.id}>
            {role.name}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}
