import React, { useState, useEffect, useRef } from "react";

//Charts
import ReactEcharts from "echarts-for-react";

import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

//Jquery
import mainURL from "../../config/environment";
import $ from "jquery";

export default function PieChart(props) {
  const { subTitle } = props;
  const { endpoint } = props;
  const { title } = props;

  const [chartSettings, setChartSettings] = useState({});

  useEffect(() => {
    let isSubscribed = true;
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    const host = JSON.parse(localStorage.getItem("userInfo")).hostName;
    $.ajax({
      method: "GET",
      url: mainURL + endpoint,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
        hostname: host,
      },
    }).done((res) => {
      if (isSubscribed) {
        setChartSettings({
          color: ["#4ea72b", "#044974"],
          // Global text styles
          textStyle: {
            fontFamily: "Roboto, Arial, Verdana, sans-serif",
            fontSize: 13,
          },

          // Add title
          /* title: {
            text: title,
            subtext: subTitle,
            left: "center",
            textStyle: {
              fontSize: 17,
              fontWeight: 500,
            },
            subtextStyle: {
              fontSize: 12,
            },
            margin: 10,
          }, */

          // Add tooltip
          tooltip: {
            trigger: "item",
            //backgroundColor: "rgba(0,0,0,0.75)",
            padding: [10, 15],
            textStyle: {
              fontSize: 13,
              fontFamily: "Roboto, sans-serif",
            },
            //formatter: "{a} <br/>{b}: +{c}$ ({d}%)"
          },

          // Add legend

          // Add series
          series: [
            {
              // name: "Increase (brutto)",
              type: "pie",
              radius: ["15%", "50%"],
              center: ["50%", "50%"],
              roseType: "radius",
              itemStyle: {
                borderWidth: 1,
                borderColor: "#fff",
              },
              data: res,
            },
          ],
        });
      }
    });

    return () => (isSubscribed = false);
  }, [endpoint]);

  return (
    <Grid item sm={6} xs={12}>
      <Card>
        <CardHeader
          title={
            <Typography variant="h5" sx={{ fontWeight: 550 }}>
              {title}
            </Typography>
          }
          subheader={
            <Typography variant="body2" color="textSecondary">
              {subTitle}
            </Typography>
          }
          titleTypographyProps={{ fontSize: "1.2rem" }}
        />
        <CardContent sx={{ width: "100%" }}>
          <ReactEcharts ref={useRef("echarts_react")} option={chartSettings} />
        </CardContent>
      </Card>
    </Grid>
  );
}
