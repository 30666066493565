import React from "react";

import { useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Dashboard from "./dashboard";

import ClientModule from "../../modules/client";
import MapModule from "../../modules/map";

import americasClientId from "../../config/appConstants";
import MapViewer from "./mapViewer";

export default function Administration(props) {
  const { handleClientClose } = props;
  const { clientId } = useParams();
  const { mapId } = useParams();
  const { clientOpen } = props;

  return (
    <Grid container height={"100%"}>
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          borderRight: "1px #C0C0C0 solid",
          display: { xs: "none", md: clientOpen ? "block" : "none" },
        }}
      >
        <ClientModule handleDrawerClose={handleClientClose} />
      </Grid>
      <Grid item md={clientOpen ? 8 : 12} xs={12}>
        {mapId === undefined ? (
          clientId === undefined || clientId === americasClientId ? (
            <Dashboard />
          ) : (
            <MapModule />
          )
        ) : (
          <MapViewer />
        )}
      </Grid>
    </Grid>
  );
}
