import React from "react";

//React router
import { useNavigate } from "react-router-dom";

//MUI
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";

export default function ProfilePopover(props) {
  const navigate = useNavigate();
  const { open } = props;

  const handleLogout = () => {
    localStorage.removeItem("userInfo");
    navigate("/login");
  };

  const handleProfileRedirection = () => {
    navigate("/profile");
    props.handleClose();
  };

  return (
    <Popover
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={Boolean(open)}
      anchorEl={open}
      onClose={props.handleClose}
    >
      <List dense>
        <ListItemButton onClick={handleProfileRedirection}>
          <ListItemText primary={"Profile"} />
        </ListItemButton>
        <ListItemButton onClick={handleLogout}>
          <ListItemText primary={"Logout"} />
        </ListItemButton>
      </List>
    </Popover>
  );
}
