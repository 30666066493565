import React from "react";

//MUI
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";

import { useNavigate } from "react-router-dom";

import mainURL from "../../config/environment";
import $ from "jquery";

export default function UserPopover(props) {
  const { showImpersonateOption } = props;
  const { showInviteOption } = props;
  const { showUpdateUser } = props;
  const { showEditRole } = props;
  const { userId } = props;
  const { roleId } = props;
  const { open } = props;

  const navigate = useNavigate();

  const handleImpersonate = () => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    $.ajax({
      method: "POST",
      url: mainURL + `login/impersonate/${userId}`,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .done((res) => {
        localStorage.setItem("userInfo", JSON.stringify(res));
        navigate("/");
        window.location.reload(false);
      })
      .fail((res) => {});
  };

  const handleOpenDialog = (action) => () => {
    switch (action) {
      case "invite-user":
        props.handleSendInvite();
        break;

      case "update-user":
        props.setUpdateDialog(true);
        break;

      case "update-role":
        navigate(`/roles/${roleId}`);
        break;

      case "impersonate":
        handleImpersonate();
        break;

      default:
        props.setDeleteDialog(true);
        break;
    }
    props.handleClose();
  };

  return (
    <Popover
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={Boolean(open)}
      anchorEl={open}
      onClose={props.handleClose}
    >
      <List dense>
        {showImpersonateOption ? (
          <ListItemButton onClick={handleOpenDialog("impersonate")}>
            <ListItemText primary={"Impersonate"} />
          </ListItemButton>
        ) : null}

        {showInviteOption ? (
          <ListItemButton onClick={handleOpenDialog("invite-user")}>
            <ListItemText primary={"Send Invitation"} />
          </ListItemButton>
        ) : null}

        {showUpdateUser ? (
          <ListItemButton onClick={handleOpenDialog("update-user")}>
            <ListItemText primary={"Edit"} />
          </ListItemButton>
        ) : null}

        {showEditRole ? (
          <ListItemButton onClick={handleOpenDialog("update-role")}>
            <ListItemText primary={"Update permissions"} />
          </ListItemButton>
        ) : null}

        <ListItemButton onClick={handleOpenDialog("delete")}>
          <ListItemText primary={"Delete"} />
        </ListItemButton>
      </List>
    </Popover>
  );
}
