import React, { useState, useEffect } from "react";

//MUI
import CircularProgress from "@mui/material/CircularProgress";
import TableContainer from "@mui/material/TableContainer";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

//Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import { useNavigate, useParams } from "react-router-dom";

import mainURL from "../../config/environment";
import $ from "jquery";

const emptyModel = {
  id: 0,
  name: "",
};

export default function UpdateRole(props) {
  //Data management
  const [permissionsToAssign, setPermissionsToAssign] = useState([]);

  const [permissions, setPermissions] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [model, setModel] = useState(emptyModel);

  const navigate = useNavigate();
  const { roleId } = useParams();

  //Notification management
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState({
    severity: "",
    message: "",
  });

  const handleShowNotification = (severity, message) => {
    setNotificationMessage({ severity: severity, message: message });
    setShowNotification(true);
    setTimeout(function () {
      setShowNotification(false);
    }, 2000);
  };

  const handlePermissionChange = (event) => {
    const target = event.target;
    const value = target.checked;
    const permissionId = parseInt(target.name);
    let newPermissions = permissionsToAssign;
    if (value) {
      newPermissions.push(permissionId);
    } else {
      newPermissions = newPermissions.filter((id) => id !== permissionId);
    }
    setPermissionsToAssign(newPermissions);
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setModel({
      ...model,
      [name]: value,
    });
  };

  const groupRows = [
    { id: 4, groupName: "Administrative", validationName: "Administrative" },
    { id: 1, groupName: "Users", validationName: "Users" },
    { id: 2, groupName: "Roles", validationName: "Roles" },
    { id: 3, groupName: "Clients", validationName: "Clients" },
  ];

  const handleSubmitRole = () => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    setLoading(true);
    $.ajax({
      method: "PUT",
      url: mainURL + `role/${roleId}`,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
      data: JSON.stringify(model),
    })
      .done((res) => {
        handleSubmitPermissions();
        handleShowNotification("success", "Role updated");
      })
      .fail(() => {
        setLoading(false);
        handleShowNotification("error", "Role not updated");
      });
  };

  const handleSubmitPermissions = () => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;

    $.ajax({
      method: "POST",
      url: mainURL + "permissions/" + roleId,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
      data: JSON.stringify(permissionsToAssign),
    })
      .done(() => {
        setLoading(false);
        navigate("/roles");
        handleShowNotification("success", "Permissions assigned");
      })
      .fail(() => {
        setLoading(false);
        handleShowNotification("error", "Permissions not assigned");
      });
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    let isSubscribed = true;
    handleShowNotification("info", "Loading permissions");
    $.ajax({
      method: "GET",
      url: mainURL + "permissions/" + roleId,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .done((res) => {
        if (isSubscribed) {
          const permissionIds = [];
          res.forEach((element) => {
            if (element.isAssigned) permissionIds.push(element.permission);
          });
          setPermissionsToAssign(permissionIds);
          setPermissions(res);
          handleShowNotification("success", "Permissions loaded successfully");
        }
      })
      .fail((res) => {
        handleShowNotification("error", res.responseText);
      });
    return () => (isSubscribed = false);
  }, [roleId]);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    const host = JSON.parse(localStorage.getItem("userInfo")).hostName;
    if (roleId !== 0) {
      $.ajax({
        method: "GET",
        url: mainURL + "role/" + roleId,
        contentType: "application/json",
        headers: {
          Authorization: "Bearer " + token,
          hostname: host,
        },
      }).done((res) => {
        console.log(res);
        setModel(res);
      });
    }
  }, [roleId]);

  return (
    <Grid container direction="column" sx={{ p: 3 }}>
      <Grid
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ p: "2rem 0" }}
        spacing={2}
        container
      >
        <Grid item md={8}>
          <Typography variant="h4">{"Update role"}</Typography>
        </Grid>

        {showNotification ? (
          <Grid item xs={12} md={4}>
            <Alert variant="outlined" severity={notificationMessage.severity}>
              {notificationMessage.message}
            </Alert>
          </Grid>
        ) : isLoading ? (
          <CircularProgress color="primary" />
        ) : (
          <Grid item xs={12} md={4} container justifyContent="flex-end">
            <FormControl required fullWidth>
              <TextField
                autoFocus
                variant="standard"
                margin="dense"
                name="name"
                label={"Role name"}
                type="text"
                value={model.name}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
        )}
      </Grid>

      <Grid container>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableBody>
              {groupRows.map((groupRow) => (
                <GroupRow
                  key={groupRow.id}
                  groupRow={groupRow}
                  permissions={permissions}
                  handlePermissionChange={handlePermissionChange}
                  permissionsToAssign={permissionsToAssign}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid container justifyContent={"flex-end"} sx={{ pt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<ExitToAppIcon />}
          onClick={() => navigate("/roles")}
        >
          {"Back to roles"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AssignmentIcon />}
          onClick={handleSubmitRole}
          sx={{ ml: 2 }}
        >
          {"Update and assign"}
        </Button>
      </Grid>
    </Grid>
  );
}

function GroupRow(props) {
  //Context
  const [open, setOpen] = useState(false);
  const { groupRow } = props;

  return (
    <TableRow>
      <TableCell colSpan={6}>
        <Grid container direction="row">
          <IconButton
            aria-label="expand row"
            size="small"
            style={{ paddingRight: "0.5em" }}
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <Typography variant="h6" component="div">
            {groupRow.groupName}
          </Typography>
        </Grid>
        <Collapse
          in={open}
          timeout="auto"
          style={{ width: "100%" }}
          unmountOnExit
        >
          <Box margin={1} width="100%">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>{"Name"}</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    {"Description"}
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }} align="center">
                    {"Is Assigned?"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.permissions.map((row) => {
                  if (row.groupName === groupRow.validationName) {
                    const validateCheck = props.permissionsToAssign.filter(
                      (x) => x === row.permission
                    );
                    const willBeAssigned = Boolean(validateCheck.length > 0);
                    return (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.shortName}
                        </TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell align="center">
                          <input
                            type="checkbox"
                            name={row.permission}
                            className="form-input-styled m-1"
                            defaultChecked={willBeAssigned}
                            onChange={props.handlePermissionChange}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  } else {
                    return null;
                  }
                })}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}
