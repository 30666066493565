import React, { useEffect, useState } from "react";

import $ from "jquery";
import mainURL from "../../config/environment";

import { useParams } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";

const emptyMap = { id: "", mapTitle: "", mapURL: "", clientId: "" };

export default function MapViewer(props) {
  const [isLoading, setLoading] = useState(true);
  const [map, setMap] = useState(emptyMap);
  const { mapId } = useParams();

  useEffect(() => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    let isSubscribed = true;
    //handleShowNotification("info", "Loading users");
    $.ajax({
      method: "GET",
      url: `${mainURL}map/${mapId}`,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .done((res) => {
        if (isSubscribed) {
          setMap(res);
          setLoading(false);
        }
      })
      .fail((res) => { });
    return () => (isSubscribed = false);
  }, [mapId]);

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems={"center"}
        height={"100vh"}
      >
        <CircularProgress />
      </Grid>
    );
  } else {
    return (
      <div className="embed-container">
        <iframe
          width="500"
          height="400"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          title={map.mapTitle}
          src={map.mapURL}
          allow={'geolocation'}

        />
      </div>
    );
  }
}
