import React, { useEffect, useState } from "react";
import Home from "../views/private/home";

import americasClientId from "../config/appConstants";

export default function ValidateUser(props) {
  const isAmericas = Boolean(
    JSON.parse(localStorage.getItem("userInfo"))?.clientId === americasClientId
  );
  const [isAdminUser, setAdminUser] = useState(isAmericas);

  useEffect(() => {
    setAdminUser(isAmericas);
  }, [isAmericas]);

  if (isAdminUser) {
    return <Home isAmericas={isAmericas} />;
  } else {
    return <Home isAmericas={isAmericas} />;
  }
}
