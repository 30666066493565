import React, { useState, useEffect } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Input from "@mui/material/Input";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import $ from "jquery";
import mainURL from "../../config/environment";
import SelectClient from "../selects/selectClient";

const emptyModel = {
  mapURL: "",
  mapTitle: "",
  clientId: "",
  mapPreview: "",
};

export default function UpdateMapDialog(props) {
  const [isFormComplete, setFormComplete] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [model, setModel] = useState(emptyModel);
  const { refresh } = props;
  const { mapId } = props;

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    setModel({
      ...model,
      [name]: value,
    });

    if (model.mapTitle !== "" && model.mapURL) {
      setFormComplete(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("userInfo")).token;

    $.ajax({
      method: "PUT",
      url: mainURL + `map/${mapId}`,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
      data: JSON.stringify(model),
    })
      .done((res) => {
        setLoading(false);
        props.handleShowNotification("success", "Map updated successfully");
        handleClear();
      })
      .fail((res) => {
        setLoading(false);

        props.handleShowNotification("error", res.responseText);
        handleClear();
      });
  };

  const handleClear = () => {
    props.handleClose();
    setModel(emptyModel);
    props.setRefresh(!refresh);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    let reader = new FileReader();
    reader.onload = (e) => {
      let image = e.target.result;
      setModel({ ...model, mapPreview: image });
    };
    reader.readAsDataURL(file);

    if (model.name !== "") {
      setFormComplete(true);
    }
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    const host = JSON.parse(localStorage.getItem("userInfo")).hostName;
    if (mapId !== "") {
      $.ajax({
        method: "GET",
        url: mainURL + "map/" + mapId,
        contentType: "application/json",
        headers: {
          Authorization: "Bearer " + token,
          hostname: host,
        },
      }).done((res) => {
        setModel(res);
      });
    }
  }, [mapId, refresh]);

  return (
    <Dialog open={props.open} onClose={props.handleClose} maxWidth="md">
      <DialogTitle>{"Edit map"}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <FormControl fullWidth required sx={{ mt: 1 }}>
            <TextField
              label={"Title"}
              onChange={handleChange}
              value={model.mapTitle}
              variant="standard"
              name="mapTitle"
              margin="dense"
              type="text"
              fullWidth
              autoFocus
              required
            />
          </FormControl>
          <FormControl fullWidth required sx={{ mt: 1 }}>
            <TextField
              label={"Map URL"}
              onChange={handleChange}
              value={model.mapURL}
              variant="standard"
              name="mapURL"
              margin="dense"
              type="text"
              fullWidth
              required
            />
          </FormControl>

          <SelectClient
            handleChange={handleChange}
            value={model.clientId}
            refresh={props.open}
            name="clientId"
            shrink
          />

          <FormControl fullWidth required sx={{ mt: 1 }}>
            <InputLabel variant="standard">{"Client logo"}</InputLabel>
            <Input type="file" onChange={handleFileChange} />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <Grid container justifyContent={"center"}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container justifyContent={"flex-end"}>
            <Button type="submit" onClick={handleClear}>
              Close
            </Button>
            <Button
              type="submit"
              disabled={!isFormComplete}
              onClick={handleSubmit}
            >
              Update
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
}
