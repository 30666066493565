import React, { useState } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
// import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import $ from "jquery";
import mainURL from "../../config/environment";
import SelectClient from "../selects/selectClient";
import SelectRole from "../selects/selectRole";

import americasClientId from "../../config/appConstants";
import { OutlinedInput, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import theme from "../../config/theme";


const emptyModel = {
  fullName: "",
  email: "",
  phoneNumber: "",
  roleId: 0,
  clientId: "",
};

const styles = {
  passwordInput: {
    backgroundColor: 'white',
    borderRadius: '4px',
    opacity: '70%',
    fontWeight: '500',
    paddingLeft: 2,
    paddingRight: 2,
    height: '2.7rem',
    border: '2px',
    // borderColor: 'red',
    fontSize: '15px',
    color: 'black'
  },

  ButtonCss: {
    textTransform: 'unset',
    backgroundColor: theme.palette.blues.main,
    fontWeight: '600',
    fontSize: '14px',
    color: '#FFFF'


  },
  closeButton: {
    textTransform: 'unset',
    fontWeight: '600',
    fontSize: '14px',
    marginRight: 2,
    backgroundColor: theme.palette.blues.light
  }
}

const CreateUserDialog = (props) => {
  const [isPasswordValid, setPasswordValid] = useState(false);
  const [isFormComplete, setFormComplete] = useState(false);
  const [isEmailValid, setEmailValid] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [model, setModel] = useState(emptyModel);
  const { refresh } = props;
  const theme = useTheme();
  const clientId = JSON.parse(localStorage.getItem("userInfo")).clientId;

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    switch (name) {
      case "email":
        value = value.toLowerCase();
        const isEmailValid = Boolean(
          value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        );

        if (isEmailValid) {
          setEmailValid(true);
        } else {
          setEmailValid(false);
        }

        setModel({
          ...model,
          [name]: value,
        });
        break;

      case "password":
        const isPasswordValid = Boolean(
          value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,}$/i)
        );
        if (isPasswordValid) {
          setPasswordValid(true);
        } else {
          setPasswordValid(false);
        }

        setModel({
          ...model,
          [name]: value,
        });
        break;

      default:
        if (clientId !== americasClientId) {
          setModel({
            ...model,
            [name]: value,
            clientId: clientId,
          });
        } else {
          setModel({
            ...model,
            [name]: value,
          });
        }
        break;
    }

    if (model.name !== "") {
      setFormComplete(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("userInfo")).token;

    $.ajax({
      method: "POST",
      url: mainURL + "user",
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
      data: JSON.stringify(model),
    })
      .done((res) => {
        setLoading(false);
        props.handleShowNotification("success", "Client created successfully");
        handleClear();
      })
      .fail((res) => {
        setLoading(false);

        props.handleShowNotification("error", res.responseText);
        handleClear();
      });
  };

  const handleClear = () => {
    props.handleClose();
    setEmailValid(false);
    setModel(emptyModel);
    setFormComplete(false);
    setPasswordValid(false);
    props.setRefresh(!refresh);
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} maxWidth="md">
      <DialogTitle>{"Create user"}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <FormControl fullWidth required sx={{ mt: 1 }}>
            {/* <TextField
              label={"Name"}
              onChange={handleChange}
              value={model.fullName}
              variant="filled"
              size="small"
              name="fullName"
              margin="dense"
              type="text"
              fullWidth
              autoFocus
              required
            /> */}

            <Typography sx={{ color: theme.palette.gray.main, fontSize: '16px', mb: '0.5rem', fontWeight: '500' }}>{"Name: "}</Typography>

            <OutlinedInput sx={styles.passwordInput}
              value={model.fullName}
              margin="dense"
              required
              name="fullName"
              placeholder="Insert name"
              type="text"
              size="small"
              onChange={handleChange}

            />
          </FormControl>
          <FormControl fullWidth required sx={{ mt: 1 }}>
            <Typography sx={{ color: theme.palette.gray.main, fontSize: '16px', mb: '0.5rem', fontWeight: '500' }}>{"email: "}</Typography>

            <OutlinedInput sx={styles.passwordInput}
              error={!isEmailValid}
              value={model.email}
              margin="dense"
              required
              name="email"
              placeholder="Insert email"
              type="text"
              size="small"
              onChange={handleChange}

            />

            {/* <TextField
              onChange={handleChange}
              error={!isEmailValid}
              value={model.email}
              variant="filled"
              size="small"
              label={"Email"}
              margin="dense"
              name="email"
              type="email"
              fullWidth
              required
            /> */}
            {!isEmailValid ? (
              <FormHelperText>{"Email is not valid"}</FormHelperText>
            ) : null}
          </FormControl>

          <FormControl fullWidth sx={{ mt: 1 }}>
            <Typography sx={{ color: theme.palette.gray.main, fontSize: '16px', mb: '0.5rem', fontWeight: '500' }}>{"Phone number: "}</Typography>

            <OutlinedInput sx={styles.passwordInput}

              value={model.phoneNumber}
              margin="dense"
              name="phoneNumber"
              placeholder="Insert phone number"
              type="text"
              size="small"
              onChange={handleChange}

            />

            {/* <TextField
              label={"Phone number"}
              onChange={handleChange}
              value={model.phoneNumber}
              variant="filled"
              size="small"
              name="phoneNumber"
              margin="dense"
              type="text"
              fullWidth
            /> */}
          </FormControl>
          {clientId === americasClientId ? (
            <SelectClient
              handleChange={handleChange}
              value={model.clientId}
              refresh={props.open}
              name="clientId"
            />
          ) : null}

          <SelectRole
            showAmericas={model.clientId === americasClientId}
            handleChange={handleChange}
            value={model.roleId}
            refresh={props.open}
            name="roleId"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <Grid container justifyContent={"center"}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container justifyContent={"flex-end"}>
            <Button sx={styles.closeButton} type="submit" onClick={handleClear}>
              Close
            </Button>
            <Button
              sx={styles.ButtonCss}
              type="submit"
              variant='contained'
              disabled={!isFormComplete && !isEmailValid && !isPasswordValid}
              onClick={handleSubmit}
            >
              Send invitation
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default CreateUserDialog;