import React, { useState } from "react";

import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";

import ChangePasswordCard from "../../components/cards/changePassword";
import UserDetailsCard from "../../components/cards/userDetails";
//import { useTheme } from "@emotion/react";

export default function Profile(props) {
  //Notification management
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState({
    severity: "",
    message: "",
  });

  const handleShowNotification = (severity, message) => {
    setNotificationMessage({ severity: severity, message: message });
    setShowNotification(true);
    setTimeout(function () {
      setShowNotification(false);
    }, 2000);
  };

  return (
    <Grid container direction="column" sx={{ p: 3 }} spacing={2}>
      {showNotification ? (
        <Grid item xs={12}>
          <Alert variant="outlined" severity={notificationMessage.severity}>
            {notificationMessage.message}
          </Alert>
        </Grid>
      ) : null}
      <UserDetailsCard handleShowNotification={handleShowNotification} />
      <ChangePasswordCard handleShowNotification={handleShowNotification} />
    </Grid>
  );
}
