import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

import { NavLink, useNavigate } from "react-router-dom";

//MUI
//import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";

//Icons
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PeopleIcon from "@mui/icons-material/People";
import MapIcon from "@mui/icons-material/Map";

import $ from "jquery";
import mainURL from "../../config/environment";
//import { useTheme } from "@emotion/react";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  //...theme.mixins.toolbar,
  minHeight: "48px",
}));

const list = [
  {
    route: "/clients",
    name: "Clients",
    permission: 9,
    //icon: <ApartmentIcon color="inherit" />,
    icon: ApartmentIcon,
  },
  {
    route: "/maps",
    name: "Map management",
    permission: 13,
    //icon: <ApartmentIcon color="inherit" />,
    icon: EditLocationAltIcon,
  },
  {
    route: "/users",
    name: "Users",
    permission: 1,
    //icon: <PeopleIcon color="inherit" />,
    icon: PeopleIcon,
  },
  {
    route: "/roles",
    name: "Roles",
    permission: 5,
    //icon: <TransferWithinAStationIcon color="inherit" />,
    icon: TransferWithinAStationIcon,
  },
  /* {
    route: "/profile",
    name: "My profile",
    permission: 1,
    //icon: <ApartmentIcon color="inherit" />,
    icon: AccountCircleIcon,
  }, */
];

const usePermissions = (refresh) => {
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    const roleId = JSON.parse(localStorage.getItem("userInfo")).roleId;
    $.ajax({
      method: "GET",
      url: mainURL + `permissions/${roleId}`,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .done((res) => {
        const aux = [];
        res.forEach((element) => {
          if (element.isAssigned) aux.push(element.permission);
        });
        setPermissions(aux);
      })
      .fail((res) => {
        if (res.status === 401) {
          alert("Session expired");
          localStorage.removeItem("userInfo");
          navigate("/login");
        }
      });
  }, [navigate]);
  return permissions;
};

export default function SidebarContent(props) {
  const { handleDrawerClose } = props;
  const { isAmericas } = props;
  //const theme = useTheme();
  const navigate = useNavigate();

  const logo =
    JSON.parse(localStorage.getItem("userInfo"))?.clientLogo ??
    "/assets/images/logo.png";

  // const websiteURL =
  //   JSON.parse(localStorage.getItem("userInfo"))?.clientURL ??
  //   "https://americasrf.com";

  const permissions = usePermissions();

  //console.log(logo);

  return (
    <React.Fragment>
      <DrawerHeader>
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={9}
            container
            justifyContent={"center"}
            alignItems="center"
          >
            <img
              src={logo}
              height={"35px"}
              style={{ maxWidth: "100%", cursor: "pointer" }}
              alt="Company logo"
              onClick={() => {
                navigate("/");
              }}
            />
          </Grid>
          <Grid item xs={3} container justifyContent={"flex-end"}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DrawerHeader>
      <Divider />
      <List>
        {isAmericas ? (
          <NavLink
            to={"/"}
            className={({ isActive }) => (isActive ? "active-link" : "link")}
          >
            <ListItem>
              <ListItemIcon>
                <DashboardIcon color="inherit" />
              </ListItemIcon>
              <ListItemText primary={"Dashboard"} />
            </ListItem>
          </NavLink>
        ) : (
          <NavLink
            to={"/"}
            className={({ isActive }) => (isActive ? "active-link" : "link")}
          >
            {/* sx={{bgcolor: ({isActive})=>(isActive? theme.palette.blues.light: 'pink')}} */}
            <ListItem>
              <ListItemIcon>
                <MapIcon color="inherit" />
              </ListItemIcon>
              <ListItemText primary={"Maps"} />
            </ListItem>
          </NavLink>
        )}
        {list.map((element, key) => {
          const allow = permissions.find((x) => x === element.permission);
          if (Boolean(allow)) {
            return (
              <NavLink
                to={element.route}
                key={key}
                className={({ isActive }) =>
                  isActive ? "active-link" : "link"
                }
              >
                <ListItem>
                  <ListItemIcon>
                    <element.icon color="inherit" />
                  </ListItemIcon>
                  <ListItemText primary={element.name} />
                </ListItem>
              </NavLink>
            );
          } else {
            return null;
          }
        })}
        <NavLink
          to={"/profile"}
          className={({ isActive }) => (isActive ? "active-link" : "link")}
        >
          <ListItem>
            <ListItemIcon>
              <AccountCircleIcon color="inherit" />
            </ListItemIcon>
            <ListItemText primary={"My profile"} />
          </ListItem>
        </NavLink>
      </List>
    </React.Fragment>
  );
}
