import { createTheme } from "@mui/material/styles";
import "../Themes/styles.css";
// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#0477b7",
    },
    secondary: {
      main: "#4ea72b",
    },
    blues: {
      light: "#EAEFF0",
      main: "#2C9ADD",
      dark: "#083F60"

    },
    gray: {
      superLight: "#FCFCFC",
      light: "#F7F7F7",
      mediumLight: "#E9E9E9",
      medium: "#CDD6D9",
      main: "#5E5E5E",
      dark: "#4E4E4E",
      mediumDark: "#494949",
      superDark: "#2F2F2F"
    }
  },

  typography: {
    fontFamily: [

      "'Inter', sans-serif",
      "'Lato', sans-serif",
      'Roboto',
      'sans-serif',

    ].join(','),
  },
});

//#0484c4 Azul claro
//#0477b7 Azull más oscuro
//#4ea72b Verde
//#888C8C Gris
//#0D0D0D Negro

export default theme;
