import React, { useState, useEffect } from "react";

import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";

//Icons
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CellTowerIcon from "@mui/icons-material/CellTower";
import GroupIcon from "@mui/icons-material/Group";

import { useNavigate, useParams } from "react-router-dom";

import mainURL from "../config/environment";
import $ from "jquery";

export default function ClientModule(props) {
  const [clients, setClients] = useState([]);
  const { handleDrawerClose } = props;
  const { clientId } = useParams();
  const navigate = useNavigate();

  const handleToggle = (client) => (event, expand) => {
    if (expand) {
      navigate(`/${client.id}`);
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    let isSubscribed = true;
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    const host = JSON.parse(localStorage.getItem("userInfo")).hostName;
    $.ajax({
      method: "GET",
      url: mainURL + `client/get-with-users`,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
        hostname: host,
      },
    }).done((res) => {
      if (isSubscribed) {
        const aux = res.sort((a, b) => a.name.localeCompare(b.name));
        setClients(aux);
      }
    });

    return () => (isSubscribed = false);
  }, [clientId]);

  return (
    <Grid>
      <Toolbar variant="dense">
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant="h5">{"Clients"}</Typography>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </Grid>
      </Toolbar>
      {clients.map((client) => (
        <Accordion
          key={client.id}
          expanded={clientId === client.id}
          onChange={handleToggle(client)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <CellTowerIcon color="primary" sx={{ mr: 2 }} />
            <Typography>{client.name}</Typography>
          </AccordionSummary>
          {client.users.map((user) => (
            <AccordionDetails sx={{ ml: 2 }} key={user.id}>
              <Grid container>
                <GroupIcon color="primary" sx={{ mr: 2 }} />
                <Typography variant="body2" color="textSecondary">
                  {user.fullName}
                </Typography>
              </Grid>
            </AccordionDetails>
          ))}
        </Accordion>
      ))}
    </Grid>
  );
}
