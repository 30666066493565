import React, { useState } from "react";

import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import { useNavigate } from "react-router-dom";

//Selfmade
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ProfilePopover from "../popovers/profile";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import { LanguageOutlined } from "@mui/icons-material";
import { Box } from "@mui/system";
import { Tooltip } from "@mui/material";

//const drawerWidth = 240;

export default function Navbar(props) {
  const { handleDrawerToggle } = props;
  const { handleClientOpen } = props;
  const { clientOpen } = props;

  const navigate = useNavigate();

  const websiteURL =
    JSON.parse(localStorage.getItem("userInfo"))?.clientURL ??
    "https://americasrf.com";
  const navbarName =
    JSON.parse(localStorage.getItem("userInfo"))?.fullName ?? "Americas";

  //Profile popover management
  const [anchorProfile, setAnchorProfile] = useState(null);
  const handleProfilePopoverOpen = (event) => {
    setAnchorProfile(event.currentTarget);
  };

  const handleProfilePopoverClose = () => {
    setAnchorProfile(null);
  };

  const handleHomeClick = () => {
    navigate("/");
    handleDrawerToggle();
  };

  return (
    <AppBar position="fixed">
      <ProfilePopover
        open={anchorProfile}
        handleClose={handleProfilePopoverClose}
      />
      <Toolbar variant="dense" sx={{ background: "#2C9ADD" }}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid container item sm={4}>
            <Grid item sm={6}>
              <IconButton
                color="inherit"
                edge="start"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              <IconButton color="inherit" edge="end" onClick={handleHomeClick}>
                <HomeIcon />
              </IconButton>
            </Grid>

            {!clientOpen ? (
              <Grid item>
                <IconButton
                  color="inherit"
                  edge="start"
                  onClick={handleClientOpen}
                >
                  <ChevronRightIcon />
                </IconButton>
              </Grid>
            ) : null}
          </Grid>
          <Grid
            item
            sm={8}
            container
            justifyContent="flex-end"
            alignItems={"center"}
          >
            <Box display={"flex"} alignItems={"center"}>
              <Tooltip
                title={"Go to NNTU website"}
                arrow
                sx={{ bgcolor: "#1C3C6C" }}
              >
                <a
                  href={websiteURL}
                  alt="Client's Web page"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LanguageOutlined sx={{ color: "white", mt: "4px", mr: 2 }} />
                </a>
              </Tooltip>
            </Box>

            <ButtonBase color="inherit" onClick={handleProfilePopoverOpen}>
              <PersonIcon sx={{ mr: 2 }} />
              <Typography
                variant="subtitle2"
                sx={{ display: { xs: "none", md: "block" } }}
              >
                {navbarName}
              </Typography>
            </ButtonBase>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
