import React, { useState } from "react";
import "../../Themes/styles.css";
//MUI
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import CssBaseline from "@mui/material/CssBaseline";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Input from "@mui/material/Input";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
//Selfmade
import Copyright from "./copyright";

//Icons
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

//React router dom
import { useNavigate, Link } from "react-router-dom";

import mainURL from "../../config/environment";
import $ from "jquery";

//Assets
import backgroundImg from "../../assets/BackgroundImg2.jpeg";
import { styled } from '@mui/material/styles';
// import { Button, OutlinedInput, Typography } from "@mui/material";
// import { styled } from '@mui/material/styles';

const styles = {
  backgroundImg: {
    backgroundImage: `url(${backgroundImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    // backgroundColor: '#0B3940',
  },
  passwordInput: {
    backgroundColor: '#E4E4E4',
    borderRadius: '4px',
    opacity: '70%',
    fontWeight: '600',
    paddingLeft: 2,
    paddingRight: 2,
    height: '2.7rem',
    border: '2px',
    borderColor: 'red'
  },
  boxCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  boxContainer: {
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // justifyContent: "center",
    width: '100%',
    height: "90%",

  },
  imgBoxContainer: {
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // justifyContent: "center",
    maxWidth: '32rem',
  },
  titleBoxContainer: {
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // justifyContent: "center",
    mt: 6
  }

}
const ButtonCss = styled(Button)`
background: #2C9ADD;
font-weight: bold;
text-transform: unset;
font-size: 16px;
&:hover,
  &:focus {
    background: #1E79AF;
  }
  &:active {
    background: #1E79AF;
  }
`

// const CssTextField = styled(TextField)({

//   '& label.Mui-focused': {
//     color: '#2C9ADD',
//   },

//   "& .MuiInputBase-root": {
//     color: '#C5DDE1',
//     backgroundColor: '#E4E4E4',
//     color: '#000000',
//     opacity: '70%',
//     fontWeight: '600'
//     // opacity: '100%'
//   },
//   "& .MuiInputLabel-root": {
//     color: '#8AB2B9',

//   },

//   "& .MuiInputBase-input": {
//     height: '0.5rem',

//   },

//   '& .MuiInput-underline:after': {
//     borderBottomColor: '#2C9ADD',
//   },
//   '& .MuiOutlinedInput-root': {
//     '& fieldset': {
//       borderColor: '#8AB2B9',
//     },
//     '&:hover fieldset': {
//       borderColor: '#55929C',
//     },
//     '&.Mui-focused fieldset': {
//       borderBottomColor: '#2C9ADD',
//     },
//   },
// });

// const FilledInputStyle = styled(FilledInput)({

//   '& label.Mui-focused': {
//     color: '#2C9ADD',
//   },

//   "& .MuiInputBase-root": {
//     color: '#C5DDE1',
//     backgroundColor: '#E4E4E4',
//     color: '#000000',
//     opacity: '70%',
//     fontWeight: '600'
//     // opacity: '100%'
//   },
//   "& .MuiInputLabel-root": {
//     color: '#8AB2B9',

//   },
//   "& .MuiInputBase-input": {
//     height: '0.5rem',
//     borderColor: '#2C9ADD',
//   },

//   "& .MuiInputBase-input-MuiInput-input:focus": {
//     borderColor: 'red',
//   },

//   '& .MuiInput-underline:after': {
//     borderColor: '#2C9ADD',
//   },
//   '& .MuiOutlinedInput-root': {
//     '& fieldset': {
//       borderColor: '#8AB2B9',
//     },
//     '&:hover fieldset': {
//       borderColor: '#55929C',
//     },
//     '&.Mui-focused fieldset': {
//       borderColor: '#2C9ADD',
//     },
//   },
// })

export default function Login() {
  const navigate = useNavigate();
  //Load management
  const [isLoading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  //Notification management
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState({
    severity: "",
    message: "",
  });

  const handleShowNotification = (severity, message) => {
    setNotificationMessage({ severity: severity, message: message });
    setShowNotification(true);
    setTimeout(function () {
      setShowNotification(false);
    }, 3000);
  };

  //Data management
  const [model, setModel] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setModel({
      ...model,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    $.ajax({
      method: "POST",
      url: mainURL + "login/authenticate-user",
      contentType: "application/json",
      data: JSON.stringify(model),
    })
      .done((res) => {
        setLoading(false);
        localStorage.setItem("userInfo", JSON.stringify(res));
        navigate("/");
      })
      .fail((res) => {
        setLoading(false);
        if (res.status === 401) {
          handleShowNotification("error", "Invalid email or password");
        }
      });
  };

  return (
    <Box component="main"
      style={styles.backgroundImg}
      sx={{
        height: "100vh",
        width: "100vw",
        padding: '1rem 2rem'
      }}>
      <CssBaseline />


      <Box sx={{
        height: "3rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      }}>
        <Typography sx={{ fontSize: '16px', color: 'white', fontWeight: 'bold' }} >{"Navajo Nation Telecommunication & Utilities"}</Typography>
        <Typography sx={{ fontSize: '14px', color: 'white' }}>{`"Connecting and Energizing the Nation!"`}</Typography>
      </Box>
      <Box sx={{ ...styles.boxContainer, ...styles.boxCenter }}>
        <Box sx={{ ...styles.imgBoxContainer, ...styles.boxCenter }}>
          <img
            src="assets/images/NNTU-logo.png"
            alt="Logo"
            style={{ height: '100%', maxWidth: '16rem' }}
          />

          <Box sx={{ ...styles.titleBoxContainer, ...styles.boxCenter }}>
            <Typography variant="h2" sx={{ color: 'white', fontWeight: '700', fontSize: '50px' }}>{"Welcome back!"}</Typography>
            <Typography variant="body1" sx={{ color: 'white', fontWeight: '500' }}>{"Please login with your email address and password."}</Typography>


          </Box>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 5, width: "100%" }}
          >
            <FormControl fullWidth>
              <Typography sx={{ color: 'white', fontSize: '16px', mb: '0.5rem' }}>{"Email *"}</Typography>
              {/* <CssTextField
                required
                id="email"
                // label="Insert your email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleChange}
                sx={{ mb: '1rem' }} /> */}

              {/* <TextField
                variant="standard"
                margin="dense"
                required
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleChange}
              /> */}

              <Input sx={styles.passwordInput}
                margin="dense"
                required
                name="email"
                type={"text"}
                id="email"
                autoComplete="email"
                onChange={handleChange}

              />


            </FormControl>
            <FormControl fullWidth sx={{ mt: 2, mb: 2 }} >
              <Typography sx={{ color: 'white', fontSize: '16px', mb: '0.5rem' }}>{"Password"}</Typography>

              <Input sx={styles.passwordInput}
                margin="dense"
                required
                name="password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                onChange={handleChange}

                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            {/* <FormControl variant="filled">
              <FilledInputStyle
                id="filled-adornment-weight"
                // value={values.weight}
                // onChange={handleChange('weight')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                aria-describedby="filled-weight-helper-text"
                inputProps={{
                  'aria-label': 'weight',
                }}
                sx={{ backgroundColor: '#E4E4E4', borderRadius: '8px', opacity: '70%', fontWeight: '600', paddingLeft: 2, paddingRight: 2 }}
              />

            </FormControl> */}

            {isLoading ? (
              <Grid container justifyContent={"center"} sx={{ mt: 3, mb: 1 }}>
                <CircularProgress />
              </Grid>
            ) : showNotification ? (
              <Alert variant="filled" severity={notificationMessage.severity}>
                {notificationMessage.message}
              </Alert>
            ) : (
              // <Button
              //   type="submit"
              //   fullWidth
              //   variant="contained"
              //   sx={{ mt: 3, mb: 2 }}
              // >
              //   Login
              // </Button>
              <ButtonCss type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 1 }}>Login</ButtonCss>
            )}
            {!isLoading ? (
              <Grid container justifyContent={"flex-end"}>
                <Grid item>
                  <Link to="/forgot-password" variant="body2" style={{ color: 'white', fontSize: '16px', fontWeight: '500' }}>
                    ¿Forgot Password?
                  </Link>
                </Grid>
              </Grid>
            ) : null}
            <Copyright sx={{ pt: 5, mt: 4 }} />
          </Box>
        </Box>
      </Box>
    </Box >
  );
}
