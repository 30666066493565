import React, { useState, useEffect } from "react";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import theme from "../../config/theme";
import $ from "jquery";
import mainURL from "../../config/environment";
import { Typography } from "@mui/material";

const useClients = (refresh) => {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    $.ajax({
      method: "GET",
      url: mainURL + "client/get-all",
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    }).done((res) => {
      setClients(res);
    });
  }, []);
  return clients;
};

export default function SelectClient(props) {
  const { handleChange, refresh, value, name } = props;
  const clients = useClients(refresh);

  return (
    <FormControl fullWidth required sx={{ mt: 1 }}>
      <Typography
        sx={{
          color: theme.palette.gray.main,
          fontSize: "16px",
          mb: "0.5rem",
          fontWeight: "500",
        }}
      >
        {"Client: "}
      </Typography>

      <Select
        onChange={handleChange}
        // variant="filled"
        value={value}
        name={name}
        size="small"
        native
      >
        <option value="" />
        {clients.map((client) => (
          <option key={client.id} value={client.id}>
            {client.name}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}
