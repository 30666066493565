import React, { useState, useEffect } from "react";

import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";

import { Link } from "react-router-dom";

//Jquery
import mainURL from "../../config/environment";
import $ from "jquery";

import PreviewIcon from "@mui/icons-material/Preview";

export default function ClientChart(props) {
  //const { subTitle } = props;
  const { title } = props;

  const [clients, setClients] = useState([]);

  useEffect(() => {
    let isSubscribed = true;
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    const host = JSON.parse(localStorage.getItem("userInfo")).hostName;
    $.ajax({
      method: "GET",
      url: mainURL + `map/get-all`,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
        hostname: host,
      },
    }).done((res) => {
      if (isSubscribed) {
        console.log(res);
        const aux = res.sort((a, b) => a.mapTitle.localeCompare(b.name));
        setClients(aux);
      }
    });

    return () => (isSubscribed = false);
  }, []);

  return (
    <Grid item sm={6} xs={12}>
      <Card sx={{ height: "100%" }}>
        <CardHeader
          title={
            <Typography variant="h5" sx={{ fontWeight: 550 }}>
              {title}
            </Typography>
          }
          /*  subheader={
            <Typography variant="body2" color="textSecondary">
              {subTitle}
            </Typography>
          } */
          titleTypographyProps={{ fontSize: "1.2rem" }}
        />
        <CardContent sx={{ width: "100%" }}>
          <List>
            {clients.map((cl) => (
              <ListItem key={cl.id}>
                <ListItemText primary={cl.mapTitle} secondary={cl.clientName} />
                <ListItemSecondaryAction>
                  <Link to={`${cl.clientId}/${cl.id}`}>
                    <PreviewIcon color="primary" fontSize="small" />
                  </Link>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </Grid>
  );
}
