import React, { useState, useEffect } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
// import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import $ from "jquery";
import mainURL from "../../config/environment";

import SelectRole from "../selects/selectRole";
import americasClientId from "../../config/appConstants";
import SelectClient from "../selects/selectClient";
import { useTheme } from "@emotion/react";
import theme from "../../config/theme";
import { OutlinedInput, Typography } from "@mui/material";


const emptyModel = {
  fullName: "",
  email: "",
  password: "",
  phoneNumber: "",
  roleId: 0,
  clientId: "",
};

const styles = {
  passwordInput: {
    backgroundColor: 'white',
    borderRadius: '4px',
    opacity: '70%',
    fontWeight: '500',
    paddingLeft: 2,
    paddingRight: 2,
    height: '2.7rem',
    border: '2px',
    // borderColor: 'red',
    fontSize: '15px',
    color: 'black'
  },

  ButtonCss: {
    textTransform: 'unset',
    backgroundColor: theme.palette.blues.main,
    fontWeight: '600',
    fontSize: '14px',
    color: '#FFFF'


  },
  closeButton: {
    textTransform: 'unset',
    fontWeight: '600',
    fontSize: '14px',
    marginRight: 2,
    backgroundColor: theme.palette.blues.light
  }
}

export default function UpdateUserDialog(props) {
  const clientId = JSON.parse(localStorage.getItem("userInfo")).clientId;
  const [isFormComplete, setFormComplete] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [model, setModel] = useState(emptyModel);
  const { refresh } = props;
  const { userId } = props;
  const theme = useTheme();


  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    setModel({
      ...model,
      [name]: value,
    });

    if (model.name !== "") {
      setFormComplete(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("userInfo")).token;

    $.ajax({
      method: "PUT",
      url: mainURL + `user/${userId}`,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
      data: JSON.stringify(model),
    })
      .done((res) => {
        setLoading(false);
        props.handleShowNotification("success", "User updated successfully");
        handleClear();
      })
      .fail((res) => {
        setLoading(false);

        props.handleShowNotification("error", res.responseText);
        handleClear();
      });
  };

  const handleClear = () => {
    props.handleClose();
    setModel(emptyModel);
    props.setRefresh(!refresh);
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    const host = JSON.parse(localStorage.getItem("userInfo")).hostName;
    if (userId !== 0) {
      $.ajax({
        method: "GET",
        url: mainURL + "user/" + userId,
        contentType: "application/json",
        headers: {
          Authorization: "Bearer " + token,
          hostname: host,
        },
      }).done((res) => {
        setModel(res);
      });
    }
  }, [userId, refresh]);

  return (
    <Dialog open={props.open} onClose={props.handleClose} maxWidth="md">
      <DialogTitle>{"Update user"}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <FormControl fullWidth required sx={{ mt: 1 }}>
            {/* <TextField
              label={"Name"}
              onChange={handleChange}
              value={model.fullName}
              variant="standard"
              name="fullName"
              margin="dense"
              type="text"
              fullWidth
              autoFocus
              required
            /> */}

            <Typography sx={{ color: theme.palette.gray.main, fontSize: '16px', mb: '0.5rem', fontWeight: '500' }}>{"Name: "}</Typography>

            <OutlinedInput sx={styles.passwordInput}
              value={model.fullName}
              margin="dense"
              required
              name="Name"
              placeholder="Insert name"
              type="text"
              size="small"
              onChange={handleChange}

            />

          </FormControl>
          <FormControl fullWidth required sx={{ mt: 1 }}>

            <Typography sx={{ color: theme.palette.gray.main, fontSize: '16px', mb: '0.5rem', fontWeight: '500' }}>{"email: "}</Typography>

            <OutlinedInput sx={styles.passwordInput}
              value={model.email}
              margin="dense"
              required
              name="email"
              placeholder="Insert email"
              type="text"
              size="small"
              onChange={handleChange}

            />
            {/* <TextField
              label={"Email"}
              onChange={handleChange}
              value={model.email}
              variant="standard"
              name="email"
              margin="dense"
              type="email"
              fullWidth
              required
            /> */}
          </FormControl>

          <FormControl fullWidth required sx={{ mt: 1 }}>
            {/* <TextField
              label={"Phone number"}
              onChange={handleChange}
              value={model.phoneNumber}
              variant="standard"
              name="phoneNumber"
              margin="dense"
              type="text"
              fullWidth
              required
            /> */}
            <Typography sx={{ color: theme.palette.gray.main, fontSize: '16px', mb: '0.5rem', fontWeight: '500' }}>{"Phone number: "}</Typography>

            <OutlinedInput sx={styles.passwordInput}
              value={model.phoneNumber}
              margin="dense"
              name="phoneNumber"
              placeholder="Insert phone number"
              type="text"
              size="small"
              // required
              onChange={handleChange}
            />

          </FormControl>

          {clientId === americasClientId ? (
            <SelectClient
              handleChange={handleChange}
              value={model.clientId}
              refresh={props.open}
              name="clientId"
              shrink
            />
          ) : null}

          <SelectRole
            showAmericas={model.clientId === americasClientId}
            handleChange={handleChange}
            value={model.roleId}
            refresh={props.open}
            name="roleId"
            shrink
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <Grid container justifyContent={"center"}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container justifyContent={"flex-end"} sx={{ mr: 2 }}>
            <Button sx={styles.closeButton} type="submit" onClick={handleClear}>
              Close
            </Button>
            <Button
              sx={styles.ButtonCss}
              type="submit"
              variant='contained'
              disabled={!isFormComplete}
              onClick={handleSubmit}
            >
              Update
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
}
