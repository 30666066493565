import * as React from "react";
import PropTypes from "prop-types";

import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";

import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";

import { Route, Routes, useLocation } from "react-router-dom";

import { useIdleTimer } from "react-idle-timer";

//Selfmade components
import Sidebar from "../../components/navigation/sidebar";
import Navbar from "../../components/navigation/navbar";
import Administration from "./administration";
import MapManagement from "./mapManagement";
import UpdateRole from "./updateRole";
import MapViewer from "./mapViewer";
import AddRole from "./addRole";
import Clients from "./clients";
import Profile from "./profile";
import Roles from "./roles";
import Users from "./users";
import Maps from "./maps";

const drawerWidth = 240;

const MainContainer = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  height: "94vh",
  [theme.breakpoints.up("xl")]: {
    height: "95vh",
  },
  flexGrow: 1,
  /* padding: theme.spacing(3), */
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

function ResponsiveDrawer(props) {
  const location = useLocation();
  const { isAmericas } = props;
  const { window } = props;

  const navigate = useNavigate();

  const [clientOpen, setClientOpen] = React.useState(true);

  const handleClientOpen = () => {
    setClientOpen(true);
  };

  const handleClientClose = () => {
    setClientOpen(false);
  };

  const [mobileOpen, setMobileOpen] = React.useState(
    !location.pathname.includes("maps")
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  //const [timeOut, setTimeOut] = React.useState(1000 * 60 * 1);

  const onIdle = (e) => {
    console.log("User is IDLE");
    localStorage.removeItem("userInfo");
    navigate("/login");
  };

  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 30,
  });

  return (
    <Box
      height={"100vh"}
      sx={{
        display: "flex",
      }}
    >
      <CssBaseline />
      <Navbar
        clientOpen={clientOpen}
        isAmericas={isAmericas}
        handleDrawerToggle={handleDrawerToggle}
        handleClientOpen={handleClientOpen}
      />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Sidebar
          container={container}
          mobileOpen={mobileOpen}
          isAmericas={isAmericas}
          setMobileOpen={setMobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          handleDrawerClose={handleDrawerClose}
        />
      </Box>
      <MainContainer open={mobileOpen}>
        <Toolbar variant="dense" />
        <Routes>
          <Route
            path="/"
            element={
              isAmericas ? (
                <Administration
                  handleClientClose={handleClientClose}
                  clientOpen={clientOpen}
                />
              ) : (
                <Maps handleDrawerClose={handleDrawerClose} />
              )
            }
          />
          <Route path="users" element={<Users isAmericas={isAmericas} />} />
          <Route path="roles" element={<Roles />} />
          <Route path="profile" element={<Profile />} />
          <Route path="clients" element={<Clients />} />
          <Route path="roles/add" element={<AddRole />} />
          <Route path="roles/:roleId" element={<UpdateRole />} />
          <Route
            path=":clientId"
            element={
              <Administration
                handleClientClose={handleClientClose}
                clientOpen={clientOpen}
              />
            }
          />
          <Route
            path=":clientId/:mapId"
            element={
              <Administration
                handleClientClose={handleClientClose}
                clientOpen={clientOpen}
              />
            }
          />
          <Route path="maps/:mapId" element={<MapViewer />} />
          <Route path="maps" element={<MapManagement />} />
        </Routes>
      </MainContainer>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
