import React, { useEffect, useState } from "react";

import ListItemText from "@mui/material/ListItemText";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import PersonIcon from "@mui/icons-material/Person";
import mainURL from "../../config/environment";
import $ from "jquery";
import { useTheme } from "@emotion/react";
import { Avatar, Box } from "@mui/material";

const emptyData = {
  id: -1,
  fullName: "",
  email: "",
  phoneNumber: "",
  roleId: 1,
  roleName: "",
  clientName: "",
  clientId: "",
  mapURL: "",
  mapTitle: "",
  clientLogo: "",
};

const styles = {
  profileDataText: {
    display: "flex",
    alignItems: "center",
  },
  textWidth: {
    width: "8rem",
    fontWeight: "600",
  },
  textWidth2: {
    width: "4rem",
    fontWeight: "600",
  },
};

export default function UserDetailsCard(props) {
  const [data, setData] = useState(emptyData);
  const theme = useTheme();

  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  };

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.substring(0, 1)}`,
    };
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    const userId = JSON.parse(localStorage.getItem("userInfo")).userId;
    let isSubscribed = true;

    $.ajax({
      method: "GET",
      url: mainURL + `user/${userId}`,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .done((res) => {
        if (isSubscribed) {
          setData(res);
        }
      })
      .fail((res) => {});
    return () => (isSubscribed = false);
  }, []);

  // data.fullName
  return (
    <Grid item sm={12}>
      <Card
        variant="outlined"
        sx={{
          bgcolor: theme.palette.gray.superLight,
          borderColor: theme.palette.gray.mediumLight,
        }}
      >
        <CardHeader
          sx={{ py: "9px" }}
          title={
            <Box display={"flex"} alignItems={"center"}>
              {" "}
              <PersonIcon
                sx={{ marginRight: 2, color: theme.palette.gray.medium }}
              />{" "}
              <Typography
                variant="h6"
                fontWeight={"600"}
                sx={{ color: theme.palette.gray.dark }}
              >
                {"User details"}
              </Typography>
            </Box>
          }
        />
        <Box sx={{ px: 2 }}>
          <Divider />
        </Box>

        <CardContent>
          <Grid container spacing={2}>
            <Grid item sm={5} md={8}>
              <Box display={"flex"} alignItems={"center"} sx={{ ml: 2 }}>
                {data.fullName && <Avatar {...stringAvatar(data.fullName)} />}
                <Typography
                  variant="h6"
                  fontWeight={"600"}
                  sx={{ color: theme.palette.gray.dark, ml: 2 }}
                >
                  {data.fullName}
                </Typography>
              </Box>

              <Box display={"flex"}>
                <List
                  sx={{ mr: 6, color: theme.palette.gray.dark, width: "50%" }}
                >
                  <ListItem sx={{ py: "2px" }}>
                    <ListItemText
                      disableTypography
                      sx={styles.profileDataText}
                      primary={
                        <Typography sx={styles.textWidth2}>Email:</Typography>
                      }
                      secondary={data.email}
                    />
                  </ListItem>
                  <ListItem sx={{ py: "2px" }}>
                    <ListItemText
                      disableTypography
                      sx={styles.profileDataText}
                      primary={
                        <Typography sx={styles.textWidth2}>Role:</Typography>
                      }
                      secondary={data.roleName}
                    />
                  </ListItem>
                </List>
                <List
                  sx={{ mr: 6, color: theme.palette.gray.dark, width: "50%" }}
                >
                  <ListItem sx={{ py: "2px" }}>
                    <ListItemText
                      disableTypography
                      sx={styles.profileDataText}
                      primary={
                        <Typography sx={styles.textWidth}>
                          Phone number:
                        </Typography>
                      }
                      secondary={data.phoneNumber}
                    />
                  </ListItem>
                  <ListItem sx={{ fontWeight: "700", py: "2px" }}>
                    <ListItemText
                      disableTypography
                      sx={styles.profileDataText}
                      primary={
                        <Typography sx={styles.textWidth}>Company:</Typography>
                      }
                      secondary={data.clientName}
                    />
                  </ListItem>
                </List>
              </Box>
            </Grid>

            <Grid item sm={5} md={4}>
              <Box display={"flex"} alignItems={"center"} height={"100%"}>
                <img src={data.clientLogo} alt="Company logo" width={"90%"} />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
