import React, { useEffect, useState } from "react";

import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

//Icons
import CellTowerIcon from "@mui/icons-material/CellTower";
import GroupIcon from "@mui/icons-material/Group";

//import * from "@mui/icons-material/us"
//Jquery
import mainURL from "../../config/environment";
import $ from "jquery";

const emptyData = { users: 0, clients: 0 };
export default function MainCharts(props) {
  const [data, setData] = useState(emptyData);

  useEffect(() => {
    let isSubscribed = true;
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    const host = JSON.parse(localStorage.getItem("userInfo")).hostName;
    $.ajax({
      method: "GET",
      url: mainURL + "dashboard/main",
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
        hostname: host,
      },
    }).done((res) => {
      if (isSubscribed) {
        setData(res);
      }
    });

    return () => (isSubscribed = false);
  }, []);
  return (
    <Grid container spacing={2} item>
      <Grid item md={6} xs={12}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={9}>
                <Typography variant="h5">Clients</Typography>
                <Typography variant="subtitle1">{data.clients}</Typography>
              </Grid>
              <Grid item xs={3}>
                <CellTowerIcon color="primary" sx={{ fontSize: 50 }} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={6} xs={12}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={9}>
                <Typography variant="h5">Users</Typography>
                <Typography variant="subtitle1">{data.users}</Typography>
              </Grid>
              <Grid item xs={3}>
                <GroupIcon color="primary" sx={{ fontSize: 50 }} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
