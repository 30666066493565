import React, { useEffect, useState } from "react";

import { Navigate } from "react-router-dom";

import ValidateUser from "./validateUser";

export default function ValidateSession(props) {
  const isLoggedin = Boolean(localStorage.getItem("userInfo"));
  const [isValidSession, setValidSession] = useState(isLoggedin);

  useEffect(() => {
    setValidSession(isLoggedin);
  }, [isLoggedin]);

  if (isValidSession) {
    return <ValidateUser />;
  } else {
    return <Navigate to="/Login" />;
  }
}
