import React, { useState } from "react";

//Material UI
import DialogContentText from "@mui/material/DialogContentText";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";

//JQuery
import mainURL from "../../config/environment";
import $ from "jquery";
import theme from "../../config/theme";

const styles = {

  ButtonCss: {
    textTransform: 'unset',
    backgroundColor: theme.palette.blues.main,
    fontWeight: '600',
    fontSize: '14px',
    color: '#FFFF',
    "&:hover": {
      backgroundColor: theme.palette.blues.dark,

    }
  },
  closeButton: {
    textTransform: 'unset',
    fontWeight: '600',
    fontSize: '14px',
    marginRight: 2,
    backgroundColor: theme.palette.blues.light,

  }
}

export default function DeleteGenericDialog(props) {
  const [isLoading, setLoading] = useState(false);
  const { errorMessage } = props;
  const { deleteURL } = props;
  const { refresh } = props;
  const { title } = props;
  const { name } = props;

  //Submit
  const handleSubmit = () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    $.ajax({
      method: "DELETE",
      url: mainURL + deleteURL,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .done((res) => {
        props.handleShowNotification("success", "Delete successfully");
        handleClear();
      })
      .fail((res) => {
        props.handleShowNotification("error", errorMessage);
        handleClear();
      });
  };

  const handleClear = () => {
    props.setRefresh(!refresh);
    props.handleClose();
    setLoading(false);
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} maxWidth="md">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Are you sure you want to ${title.toLowerCase()} ${name}`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <Grid container justifyContent={"center"}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container justifyContent={"flex-end"}>
            <Button sx={styles.closeButton} onClick={props.handleClose} color="primary">
              {"Cancel"}
            </Button>
            <Button sx={styles.ButtonCss} onClick={handleSubmit} color="primary" autoFocus>
              {"Accept"}
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
}
