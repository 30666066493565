import { Box, Divider, Grid, Typography, useTheme } from "@mui/material";
/* import facebookLogo from "../../assets/socialmedia/facebook.png";
import instagramLogo from "../../assets/socialmedia/instagram.png";
import TwitterLogo from "../../assets/socialmedia/twitter.png"; */

const Footer = () => {
  const theme = useTheme();
  const logo =
    JSON.parse(localStorage.getItem("userInfo"))?.clientLogo ??
    "/assets/images/logo.png";

  /*   const FacebookUrl = "https://es-la.facebook.com/";
  const InstagramUrl = "https://www.instagram.com/";
  const TwitterUrl = "https://twitter.com/?lang=es"; */

  return (
    <Box
      height={"auto"}
      width={"100%"}
      sx={{
        bgcolor: theme.palette.gray.light,
        marginTop: "auto",
        display: "flex",
        p: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={4}>
          <img
            src={logo}
            height={"70px"}
            style={{ maxWidth: "100%", cursor: "pointer" }}
            alt="Company logo"
          />
          <Typography
            sx={{
              fontWeight: "700",
              color: theme.palette.gray.dark,
              fontSize: "14px",
            }}
          >
            Navajo Nation Telecommunication & Utilities
          </Typography>

          <Typography sx={{ color: theme.palette.gray.dark, fontSize: "12px" }}>
            {" "}
            The Navajo Nation Telecommunication & Utilities (NNTU) department
            under the Division of General Services provides access to essential
            telecommunication and utilities service for the Navajo Nation
            government.{" "}
          </Typography>
        </Grid>
        <Grid item md={7}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Divider orientation="vertical" />
            <FooterText
              Title={"COMPANY"}
              subTitle={"Office Building No. 2528"}
              body1={"Phone:  928-871-7740"}
              body2={"Fax:  928-871-7741"}
            />
            <FooterText
              Title={"CONTACT"}
              body1={"PO Box 2928"}
              body2={"Morgan Boulevart"}
              body3={"Window Rock, Arizona 86515"}
            />
            <FooterText
              Title={"RADIO SHOP"}
              subTitle={"Radio shop No. 7447"}
              body1={"Phone: 928-871-7743"}
              body2={"Fax:  928-871-7742"}
            />
          </Box>
        </Grid>

        {/* <Grid item md={1}>
          <Box
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent="center"
            alignItems={"flex-end"}
          >
            <a
              href={FacebookUrl}
              alt="Client's Web page"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={facebookLogo}
                height={"35px"}
                style={{ maxWidth: "100%", cursor: "pointer" }}
                alt="Facebook"
              />
            </a>

            <a
              href={InstagramUrl}
              alt="Client's Web page"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={instagramLogo}
                height={"35px"}
                style={{ maxWidth: "100%", cursor: "pointer" }}
                alt="Facebook"
              />
            </a>
            <a
              href={TwitterUrl}
              alt="Client's Web page"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={TwitterLogo}
                height={"35px"}
                style={{ maxWidth: "100%", cursor: "pointer" }}
                alt="Facebook"
              />
            </a>
          </Box>
        </Grid> */}
      </Grid>
    </Box>
  );
};

const FooterText = ({ Title, subTitle, body1, body2, body3 }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        color: theme.palette.gray.dark,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <Typography sx={{ fontWeight: "700", fontSize: "17px" }}>
        {Title}
      </Typography>
      {subTitle && (
        <Typography sx={{ fontWeight: "600", fontSize: "14px" }}>
          {subTitle}
        </Typography>
      )}
      <Typography sx={{ fontSize: "14px" }}>{body1} </Typography>
      <Typography sx={{ fontSize: "14px" }}>{body2} </Typography>
      {body3 && <Typography sx={{ fontSize: "14px" }}>{body3} </Typography>}
    </Box>
  );
};
export default Footer;
