import React from "react";
import Drawer from "@mui/material/Drawer";

import SidebarContent from "./sidebarContent";
import { useTheme } from "@emotion/react";

const drawerWidth = 240;

export default function Sidebar(props) {
  const { handleDrawerToggle } = props;
  const { handleDrawerClose } = props;
  const { mobileOpen } = props;
  const { isAmericas } = props;
  const { container } = props;
  const theme = useTheme();

  return (
    <React.Fragment>
      <Drawer
        container={container}
        variant="persistent"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{

          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",

          },
        }}
      >
        <SidebarContent
          handleDrawerClose={handleDrawerClose}
          isAmericas={isAmericas}
        />
      </Drawer>
      <Drawer
        variant="persistent"
        sx={{
          "& .MuiPaper-root.MuiDrawer-paper": {
            background: theme.palette.gray.light
          },

          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        open={mobileOpen}
      >
        <SidebarContent
          handleDrawerClose={handleDrawerClose}
          isAmericas={isAmericas}
        />
      </Drawer>
    </React.Fragment >
  );
}
